import React, { useEffect, useState } from "react"
import cargar from "@components/pagePostulacion/image/insert/cargar.svg"
import error from "@components/pagePostulacion/image/insert/error.svg"
import listo from "@components/pagePostulacion/image/insert/listo.svg"
import check from "@components/pagePostulacion/image/insert/check.svg"
import Recaptcha from "react-google-recaptcha"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from "react-loader-spinner"

// eslint-disable-next-line no-undef
const KEY_CAPTCHA = process.env.KEY_CAPTCHA

const Start = ({ data }) => {
  const [imageInput, setImageInput] = useState(cargar)
  const [textInput, setTextInput] = useState("Adjunta tu archivo aquí")
  const [classInput, setClassInput] = useState("class-cargar")
  const [pdfAttached, setPDFAttached] = useState(false)
  const [valueCaptcha, setValueRecaptcha] = useState(null)
  const onChangeFile = evt => {
    const file = evt.target.files[0]
    if (file.type === "application/pdf") {
      if (file.size <= 1048576) {
        // 1MB en bytes
        setImageInput(listo)
        setTextInput(
          <>
            Archivo listo <br />
            {file.name}{" "}
          </>
        )
        setClassInput("class-listo")
        setPDFAttached(file)
      } else {
        // El archivo excede el tamaño máximo permitido
        setImageInput(error)
        setTextInput(
          <>
            El archivo no se logró subir correctamente. <br />
            Recuerda subir tu archivo en formato PDF tamaño máximo 1MB.
          </>
        )
        setClassInput("class-error")
      }
    } else {
      // El tipo de archivo no es PDF
      setImageInput(error)
      setTextInput(
        <>
          El archivo no se logró subir correctamente. <br />
          Por favor, vuelva a intentarlo. <br />
          Recuerda subir tu archivo en formato PDF tamaño máximo 1MB.
        </>
      )
      setClassInput("class-error")
    }
  }
  const [isVerified, setIsVerified] = useState(false)
  const handleRecaptchaVerify = response => {
    if (response) {
      setIsVerified(true)
      setValueRecaptcha(response)
    } else {
      setIsVerified(false)
    }
  }

  //Estado para mostrar el spin de cargando en boton enviar
  const [spin, setSpin] = useState(false)

  const onSubmitCV = async evt => {
    evt.preventDefault()

    setSpin(true)
    try {
      const formData = new FormData()
      formData.append("landing_postulation", "general") // Valores aceptados: middle_backend_developer, middle_frontend_developer, fullstack_developer_php, general
      formData.append("g-recaptcha-response", valueCaptcha)
      formData.append("booking_attachment", pdfAttached)

      // const uri = "http://localhost"
      const uri = ""
      const response = await fetch(`${uri}/backend/postulate.php`, {
        method: "POST",
        body: formData,
      })
      const statusText = response.statusText
      if (statusText === "FAILED_VALIDATION_CAPTCHA") {
        setImageInput(error)
        setTextInput(
          <>
            No se pudo validar el captcha <br /> Por favor, refresque y vuelva a
            intentarlo{" "}
          </>
        )
        setClassInput("class-error")
      } else {
        setSpin(false)
        setImageInput(null)
        setTextInput(
          <>
            ¡Gracias por tu postulación! <br />
            💛{" "}
          </>
        )
        setClassInput("class-enviado")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const [botonDeshabilitado, setBotonDeshabilitado] = useState(true)

  useEffect(() => {
    if (pdfAttached && isVerified) {
      setBotonDeshabilitado(false)
    }
  }, [pdfAttached, isVerified])

  return (
    <section className="container-insert-postulacion">
      <p className="title-insert-postulacion">{data.title} </p>
      <p className="text-insert-postulacion">{data.text}</p>
      <div className="container-insert-postulacion-pdf">
        <div className="container-insert-pdf">
          <form encType="multipart/form-data">
            {classInput === "class-enviado" ? null : (
              <>
                <p className="container-insert-pdf-title">Adjunta tu CV aquí</p>
                <p className="container-insert-pdf-text">
                  Recuerda subir tu archivo en formato PDF con tamaño máximo
                  1MB.
                </p>
              </>
            )}
            <div>
              <label className={`container-insert-pdf-banner ${classInput}`}>
                <img src={imageInput} alt="" />
                <span className="container-insert-pdf-banner-text">
                  {textInput}
                </span>
                <input
                  className="FileUpload1"
                  id="FileInput"
                  name="booking_attachment"
                  type="file"
                  accept="application/pdf"
                  onChange={onChangeFile}
                />
              </label>
            </div>
            {classInput === "class-enviado" ? null : (
              <>
                <div>
                  <Recaptcha
                    sitekey={KEY_CAPTCHA} // ESTO DEBE IR EN .env
                    onChange={handleRecaptchaVerify}
                  />
                </div>
              </>
            )}

            {classInput === "class-enviado" ? (
              <button
                className="btn-start-pricing"
                onClick={() => {
                  document.body.scrollTop = 0 // For Safari
                  document.documentElement.scrollTop = 0
                }}
              >
                Seguir viendo otras posiciones
              </button>
            ) : (
              <>
                <br />{" "}
                <button
                  className={
                    botonDeshabilitado
                      ? "btn-start-desabilitado"
                      : "btn-start-postulacion"
                  }
                  onClick={onSubmitCV}
                  disabled={botonDeshabilitado}
                >
                  Aplicar ahora{" "}
                  {spin ? (
                    <Loader
                      type="Oval"
                      color="#5e5e5e"
                      height={20}
                      width={20}
                    />
                  ) : (
                    ""
                  )}
                </button>
              </>
            )}
          </form>
        </div>
        <div className="container-insert-check">
          {data.areas.map((ele, i) => {
            return (
              <p key={i} className="container-insert-check-text">
                <img src={check} alt="" style={{ width: "auto" }} />
                {ele}
              </p>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Start
