import React from "react"
import { Link } from "gatsby"

const Start = ({ data, location }) => {

 
  return (
    <>
      <section className="container-share-postulacion">
        <p className="title-share-postulacion">Encuentra tu puesto ideal </p>
        {data.map((ele, i) => {
          return (
            <Link
              to={`/jobs-beex${ele.link}/${location.search}`}
              key={i}
              className="banner-description-puesto"
            >
              <p>{ele.puesto}</p>
              <p>{ele.area}</p>
              <p className="banner-description-puesto-time">
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_12292_750)">
                    <path
                      d="M6.11544 11.6778L8.76457 9.69094V5.64372C8.76457 5.23677 9.09351 4.90784 9.50045 4.90784C9.90739 4.90784 10.2363 5.23677 10.2363 5.64372V10.0589C10.2363 10.2907 10.1274 10.5093 9.94198 10.6476L6.99853 12.8552C6.86609 12.9546 6.71153 13.0024 6.55775 13.0024C6.33331 13.0024 6.11255 12.9016 5.9683 12.7073C5.72394 12.3827 5.79018 11.9214 6.11544 11.6778Z"
                      fill="#60636D"
                    />
                    <path
                      d="M9.5 0.5C14.7386 0.5 19 4.76138 19 10C19 15.2386 14.7386 19.5 9.5 19.5C4.26138 19.5 0 15.2386 0 10C0 4.76138 4.26138 0.5 9.5 0.5ZM9.5 18.0283C13.9262 18.0283 17.5283 14.4262 17.5283 10C17.5283 5.57378 13.9262 1.97172 9.5 1.97172C5.07304 1.97172 1.47172 5.57378 1.47172 10C1.47172 14.4262 5.07378 18.0283 9.5 18.0283Z"
                      fill="#60636D"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_12292_750">
                      <rect
                        width="19"
                        height="19"
                        fill="white"
                        transform="matrix(-1 0 0 1 19 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                {ele.time}
              </p>
            </Link>
          )
        })}
      </section>
    </>
  )
}

export default Start
